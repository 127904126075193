<template>
  <div class="page">
    <div class="page-top">
      <div class="margin-bot">
        <el-radio-group v-model="type" @change="onchange1">
          <el-radio-button :label="0">按交易渠道</el-radio-button>
          <el-radio-button :label="1">按收款方式</el-radio-button>
          <el-radio-button :label="2">折扣统计</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="选择时间：">
            <el-date-picker
              v-model="value"
              range-separator="-"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            />
          </el-form-item>
          <el-form-item label="交易渠道：">
            <el-select
              v-model="formInline.orderSource"
              placeholder="请选择交易渠道"
            >
              <el-option label="全部" value="" />
              <el-option label="安卓" value="1" />
              <el-option label="IOS" value="2" />
              <el-option label="小程序" value="3" />
              <el-option label="收银台" value="4" />
            </el-select>
          </el-form-item>
          <el-form-item label="选择商户：">
            <el-select
              v-model="formInline.merchantNo"
              placeholder="请选择商户"
              @change="onchange"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.merchantNo"
                :label="item.merchantName"
                :value="item.merchantNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择店铺：">
            <el-select v-model="formInline.shopNo" placeholder="请选择店铺">
              <el-option
                v-for="item in shopList"
                :key="item.shopNo"
                :label="item.shopName"
                :value="item.shopNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getList">查询</el-button>
            <el-button type="primary" @click="onSubmit">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="page-bot">
      <div v-if="type == 0">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            width="55"
            label="序号"
            align="center"
          />
          <el-table-column prop="shopName" label="门店名称" align="center" />
          <el-table-column prop="" label="销售时间" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.startTime }}</div>
              <div>{{ scope.row.endTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="交易渠道" align="center">
            <template slot-scope="scope">
              {{ scope.row.orderSourceName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="tranAmount"
            label="销售额（元）"
            align="center"
          />
          <el-table-column
            prop="refundPrice"
            label="退款金额（元）"
            align="center"
          />
          <el-table-column
            prop="discountPrice"
            label="优惠金额（元）"
            align="center"
          />
          <el-table-column
            prop="setZeroAmount"
            label="抹零金额（元）"
            align="center"
          />
          <el-table-column prop="price" label="实收金额（元）" align="center" />
        </el-table>
      </div>
      <div v-if="type == 1">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            width="55"
            label="序号"
            align="center"
          />
          <el-table-column prop="shopName" label="门店名称" align="center" />
          <el-table-column prop="" label="统计时间" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.startTime }}</div>
              <div>{{ scope.row.endTime }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="payTypeText" label="收款方式" align="center" />
          <el-table-column
            prop="tranAmount"
            label="收款金额（元）"
            align="center"
          />
        </el-table>
      </div>
      <div v-if="type == 2">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            width="55"
            label="序号"
            align="center"
          />
          <el-table-column prop="shopName" label="门店名称" align="center" />
          <el-table-column
            prop=""
            label="销售时间"
            align="center"
            min-width="100px"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.startTime }}</div>
              <div>{{ scope.row.endTime }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="cashierName" label="收银员" align="center" />
          <el-table-column label="交易渠道" align="center">
            <template slot-scope="scope">
              {{
                scope.row.orderSource == 1
                  ? "安卓"
                  : scope.row.orderSource == 2
                  ? "IOS"
                  : scope.row.orderSource == 3
                  ? "小程序"
                  : "收银台"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="实收金额（元）" align="center" />
          <el-table-column
            prop="memberPrice"
            label="会员折扣（元）"
            align="center"
          />
          <el-table-column
            prop="marketingPrice"
            label="促销折扣（元）"
            align="center"
          />
          <el-table-column
            prop="voucherPrice"
            label="优惠券折扣（元）"
            align="center"
          />
          <el-table-column
            prop="manualDiscountPrice"
            label="整单折扣（元）"
            align="center"
          />
          <el-table-column
            prop="freeAmount"
            label="免单金额（元）"
            align="center"
          />
          <el-table-column
            prop="setZeroAmount"
            label="抹零（元）"
            align="center"
          />
          <el-table-column
            prop="discountPrice"
            label="折扣合计（元）"
            align="center"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getOrderReportByStore,
  getOrderReportByPayChannel,
  getOrderReportByDiscounts,
  queryMerchantListByMerCode,
  queryShopListV279,
} from "@/api/product/index";
export default {
  data() {
    return {
      shopList: [],
      type: 0,
      formInline: {
        orderSource: "",
        merchantNo: "",
        endTime: "",
        startTime: "",
        shopNo: "",
        accountType: "0",
      },
      value: [],
      tableData: [],
      merchantList: [],
    };
  },
  created() {
    this.gettime();
    this.queryMerchantListByMerCode();
  },
  mounted() {},
  methods: {
    onchange1() {
      this.tableData = [];
    },
    onchange(e) {
      this.formInline.shopNo = "";
      this.queryShopList();
    },
    getList() {
      if(!this.formInline.merchantNo) return this.$message.error('请选择商户')
      if (this.value) {
        this.formInline.startTime = this.value[0];
        this.formInline.endTime = this.value[1];
      }
      let data = {
        params: this.formInline,
      };
      switch (this.type) {
        case 0:
          getOrderReportByStore(data).then((res) => {
            if (res.data.flag) {
              res.data.data.forEach((element) => {
                if (this.value) {
                  element.startTime = this.value[0];
                  element.endTime = this.value[1];
                } else {
                  element.startTime = "";
                  element.endTime = "";
                }
              });
              this.tableData = res.data.data;
            } else {
              this.$message.error(res.data.msg);
            }
          });
          break;
        case 1:
          getOrderReportByPayChannel(data).then((res) => {
            if (res.data.flag) {
              res.data.data.forEach((element) => {
                if (this.value) {
                  element.startTime = this.value[0];
                  element.endTime = this.value[1];
                } else {
                  element.startTime = "";
                  element.endTime = "";
                }
              });
              this.tableData = res.data.data;
            } else {
              this.$message.error(res.data.msg);
            }
          });
          break;
        case 2:
          getOrderReportByDiscounts(data).then((res) => {
            if (res.data.flag) {
              res.data.data.forEach((element) => {
                if (this.value) {
                  element.startTime = this.value[0];
                  element.endTime = this.value[1];
                } else {
                  element.startTime = "";
                  element.endTime = "";
                }
              });
              this.tableData = res.data.data;
            } else {
              this.$message.error(res.data.msg);
            }
          });
          break;
      }
    },
    queryShopList() {
      let data = {
        params: {
          merchantNo: this.formInline.merchantNo,
        },
      };
      queryShopListV279(data).then((res) => {
        if (res.data.flag) {
          this.shopList = res.data.data;
        }
      });
    },
    queryMerchantListByMerCode() {
      let data = {
        params: {
          merCode: JSON.parse(sessionStorage.getItem("LoginInfo")).merCode,
          merType: JSON.parse(sessionStorage.getItem("LoginInfo")).merType,
        },
      };
      queryMerchantListByMerCode(data).then((res) => {
        if (res.data.flag) {
          this.merchantList = res.data.data;
        }
      });
    },
    gettime() {
      const time = new Date();
      const year = time.getFullYear();
      const month = time.getMonth();
      const day = time.getDate();
      const time1 = year + "-" + month + "-" + day + " " + "00:00:00";
      const time2 = year + "-" + (month + 1) + "-" + day + " " + "23:59:59";
      this.value = [time1, time2];
    },
    onSubmit() {
      this.formInline.orderSource = "";
      this.formInline.merchantNo = "";
      this.formInline.shopNo = "";
      this.tableData = [];
    },
  },
};
</script>
<style scoped>
.page-top {
  padding: 10px;
}
.margin-bot {
  margin-bottom: 15px;
}
.page-bot {
  padding: 15px;
}
</style>