import request from "../../utils/request";
let baseUrl = process.env.VUE_APP_BASE_URL2;

export function queryShopList(params) {
    return request({
      url: `${baseUrl}/product/shop/queryShopList/v287`,
      method: "POST",
      data: params
    });
}
export function queryMerchantListByMerCode(params) {
    return request({
      url: `${baseUrl}/sellercenter/merMain/queryMerchantListByMerCode`,
      method: "POST",
      data: params
    });
}
  
export function agentBaseSet(params) {
    return request({
      url: `${baseUrl}/op/agentBaseSet/query/list/v275`,
      method: "POST",
      data: params
    });
}

export function postSaveMerOffline(params) {
  return request({
    url: `${baseUrl}/sellercenter/merMain/save/mer/offline`,
    method: "POST",
    data: params
  });
}

export function addshop(params) {
  return request({
    url: `${baseUrl}/product/shop/add/v279`,
    method: "POST",
    data: params
  });
}

export function getOrderReportByDiscounts(params) {
  return request({
    url: `${baseUrl}/statistics/order/reportByDiscounts`,
    method: "POST",
    data: params
  });
}

export function getOrderReportByPayChannel(params) {
  return request({
    url: `${baseUrl}/statistics/order/reportByPayChannel/v285`,
    method: "POST",
    data: params
  });
}

export function getOrderReportByStore(params) {
  return request({
    url: `${baseUrl}/statistics/order/reportByStore`,
    method: "POST",
    data: params
  });
}

export function queryShopListV279(params) {
  return request({
    url: `${baseUrl}/product/shop/queryShopList/v279`,
    method: "POST",
    data: params
  });
}

export function postMemberModel(params) {
  return request({
    url: `${baseUrl}/sellercenter/mer/change/update/memberModel`,
    method: "POST",
    data: params
  });
}

export function getShopPayData(params) {
  return request({
    url: `${baseUrl}/pay/payConfig/query/by/merchantNo/and/type`,
    method: "POST",
    data: params
  });
}

export function preShopPayData(params) {
  return request({
    url: `${baseUrl}/pay/payConfig/add/single/v272`,
    method: "POST",
    data: params
  });
}


